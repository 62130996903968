import { apiSlice } from '../../apiSlice';
import { updateIsLoading } from './globalsSlice';

export const globalsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        rotateImage: builder.mutation<{image_path: string, image_url: string, message: string},{ image_path: string, angle: string, signal?: AbortSignal }>({
            query: ({image_path='', angle='', signal}) => ({
                url: `rotate_image?image_path=${image_path}&angle=${angle}`,
                method: 'POST',
                body: {},
                signal,
            })
        })
    })
})

export const { useRotateImageMutation } = globalsApiSlice;
